/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$(".hamburger").click(function(){
  $(this).toggleClass("is-active");
  $(".nav-primary").animate({
      //width: "toggle",
      opacity: "toggle"
  }, 300);
});

$('.slider-default').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  dots: true,
  speed: 600,
});

var $status = $('.pagingInfo');
var $slickElement = $('.slider-hero');

$slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    if (slick.slideCount > 1) {
      $status.html('<span>' + i + '</span><span class="divide">/</span><span>' + slick.slideCount + '</span>');
    }
});

$slickElement.slick({
  nextArrow: '<span class="slick-arrow arrow-right"><i class="fas fa-arrow-right"></i></span>',
  prevArrow: '<span class="slick-arrow arrow-left"><i class="fas fa-arrow-left"></i></span>',
  autoplay: true,
  autoplaySpeed: 7000,
  dots: true,
  speed: 600,
  arrows: true,
  appendArrows: '.hero-slider-controls .controls-holder',
});



        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

function blokkenheight() {
   
  $('.row-columns').each(function(){
  
    $('.featured-block-height',this).height('auto');

    if ($(window).width() > 767) {
      var highestBox = 0;
      $('.featured-block-height', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.featured-block-height',this).height(highestBox);
      $('.featured-image',this).outerHeight(highestBox);
    }

  });

  $('.row-columns').each(function(){
  
    $('.news-block-height',this).height('auto');

    if ($(window).width() > 767) {
      var highestBox = 0;
      $('.news-block-height', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.news-block-height',this).height(highestBox);
    }

  });

  $('.row-columns').each(function(){
  
    $('.review-block-height',this).height('auto');

    if ($(window).width() > 767) {
      var highestBox = 0;
      $('.review-block-height', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.review-block-height',this).height(highestBox);
    }

  });

}

WebFont.load({
    google: {
      families: ['Merriweather', 'Open+Sans:300,400,600']
    },
    active: function () { blokkenheight(); }
  });

$(window).resize(function() {
  blokkenheight();
});

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
